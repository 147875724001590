import firebase from 'firebase/compat/app'
//import { deleteUser  } from "firebase/auth"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchProfiles(ctx, data = {}) {
            return new Promise(resolve => {
                let page = data.page
                let perPage = data.perPage

                let query = firebase
                  .firestore()
                  .collection('profiles')
                  .limit(perPage)
                  .orderBy('created_at', 'desc')
                  .startAfter(page)

                if (data.filter) {
                    query = query.where('nickname', '>=', data.filter)
                    query = query.where('email', '>=', data.filter)
                    query = query.where('phone', '>=', data.filter)
                }

                query.get()
                  .then(snapshot => {
                      let users = []
                      snapshot.docs.forEach(u => {
                          const user = u.data()
                          user.id = u.id
                          user.ref = u

                          users.push(user)
                      })
                      // console.log(users)
                      resolve(users)
                  })
            })
        },
        deleteUser(ctx, data) {
            return new Promise(resolve => {
                const fn = firebase.functions().httpsCallable('deleteProfileUser')
                fn({email: data}).then(users => {
                    resolve(users)
                }).catch(() => {
                    //console.log(err)
                })
            })
        }
        /* addUser(ctx, userData) {
             return new Promise((resolve, reject) => {
                 firebase.auth().createUserWithEmailAndPassword(userData.username, userData.password).then(() => {
                     firebase.firestore().collection('admin_users').add({username: userData.username}).then(() => {
                         resolve(true)
                     })
                 }).catch(err => {
                     reject(err)
                 })
             })
         },*/
        /*editUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                //firebase.auth().up

            })
        },
        deleteUser(ctx, {id}) {
            return new Promise((resolve, reject) => {

            })
        }*/
    }
}
