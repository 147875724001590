import {ref} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
    //Use toast
    const toast = useToast()
    const currentPage = ref(1)
    const items = ref([])
    const perPage = 10
    const hasMorePages = ref(true) // = computed(() => store.getters['app/counters'].profilesCount)
    const filter = ref('')
    const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        //{key: 'id'},
        //{key: 'first_name', label: 'Nombre'},
        {
            key: 'nickname',
            label: 'Nombre de usuario'
        },
        {
            key: 'created_at',
            label: 'Fecha de registro'
        },

        {
            key: 'email',
            label: 'Correo electrónico'
        },
        {
            key: 'phone',
            label: 'Teléfono'
        },
        {
            key: 'birthday',
            label: 'Fecha de nacimiento'
        },
        {
            key: 'actions',
            label: 'Acciones'
        },
    ]

    const refetchData = () => {
        refUserListTable.value.refresh()
    }

    const fetchUsers = () => {
        const data = {
            perPage: perPage,
            page: items.value.length > 1 ? items.value[items.value.length - 1].ref : '',
            filter: filter.value
        }

        store
            .dispatch('app-profiles/fetchProfiles', data)
            .then(response => {
                if (response.length < perPage) {
                    hasMorePages.value = false
                }
                items.value.push(...response)
            })
            .catch(() => {
                // console.log(err)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    fetchUsers()

    return {
        fetchUsers,
        tableColumns,
        refUserListTable,
        refetchData,
        currentPage,
        perPage,
        filter,
        hasMorePages,
        items
    }
}
