<template>
  <div>

    <!--
            <div>
                <b-form-group>
                    <label>Buscar usuarios por nombre, teléfono o correo electrónico</label>
                    <b-input-group>
                        <b-form-input v-model="filter"></b-form-input>
                        <b-input-group-append>
                            <b-button
                                variant="primary"
                                @click="fetchUsers"
                            >
                                <feather-icon
                                    size="16"
                                    icon="SearchIcon"
                                />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
    -->

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(nickname)="data">
          <b-avatar :src="data.item.image" class="mr-1"></b-avatar>
          {{ data.item.nickname ? data.item.nickname : '-' }}
        </template>

        <!-- Column: User -->
        <template #cell(birthday)="data">
          {{
            data.item.birthday.toString()
                .substring(0, 10)
          }}
        </template>

        <template #cell(created_at)="data">
          {{
            (new Date(data.item.created_at.seconds * 1000)).toLocaleDateString()
          }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button-group>
            <!--                                        <b-button variant="primary" @click="editUser(data.item)">
                                                        <feather-icon
                                                            icon="EditIcon"
                                                            size="16"
                                                            class="align-middle"
                                                        />
                                                    </b-button>-->
            <b-button variant="danger" @click="deleteUser(data.item)">
              <feather-icon
                  icon="LockIcon"
                  size="16"
                  class="align-middle"
              />
            </b-button>
          </b-button-group>
        </template>

      </b-table>

      <div class="ml-2 mb-2 d-flex align-items-center justify-content-center" v-if="hasMorePages">
        <b-button variant="primary" @click="currentPage++;fetchUsers()">
          Cargar más perfiles
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BButton,
  BButtonGroup,
  /* BFormInput,
   BFormGroup,
   BInputGroup,
   BInputGroupAppend,*/
} from 'bootstrap-vue'
import store from '@/store'
import {onUnmounted, ref} from '@vue/composition-api'
import useProfilesList from './useProfilesList'
import profileStoreModule from '@/views/profiles/profileStoreModule'
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
  name: 'ProfilesView',
  components: {
    BCard,
    BTable,
    BAvatar,
    BButtonGroup,
    BButton,
    /*BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,*/
  },
  methods: {
    addNew() {
      this.userSelected = {}
      this.addNewUser = true
      this.isAddNewUserSidebarActive = true
    },
    editUser(user) {
      this.userSelected = user
      this.isAddNewUserSidebarActive = true
      this.addNewUser = false
    },
    deleteUser(user) {

      this.$swal({
        title: '¿Seguro que desea eliminar el usuario?',
        text: 'Ya no podrá acceder al sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store
                  .dispatch('app-profiles/deleteUser', user.email)
                  .then(response => {
                    if (response) {
                      this.refetchData()
                    }
                  })
                  .catch(() => {
                    // console.log(err)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
            }
          })
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-profiles'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, profileStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchUsers,
      tableColumns,
      refUserListTable,
      refetchData,
      currentPage,
      perPage,
      filter,
      hasMorePages,
      items
    } = useProfilesList()

    const addNewUser = ref(true)
    const userSelected = ref({})

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      refUserListTable,
      refetchData,
      addNewUser,
      userSelected,
      currentPage,
      perPage,
      filter,
      hasMorePages,
      items
    }
  }
}
</script>

<style>
li.page-item [role="menuitemradio"], li.page-item:last-child, li.page-item:first-child {
  display: none;
}

li.page-item.active [role="menuitemradio"] {
  display: flex;
}
</style>
